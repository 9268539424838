import React, { useState } from "react"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { ComponentMotion } from "../../utils/componentMotion"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const CreationItem = ({ creation }) => {
  const images = creation.pictureGallery.map((picture) =>
    getSrc(picture.gatsbyImageData)
  )

  const imagesThumb = creation.pictureGallery.map(
    (picture) => picture.gatsbyImageData
  )

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const prevIndex = (index + images.length - 1) % images.length
  const nextIndex = (index + images.length + 1) % images.length

  return (
    <div className="py-6 lg:py-0 px-6 lg:px-0">
      <ComponentMotion viewThreshold={0.2} yMotion={0}>
        <div className="mt-6 lg:mt-12">
          <div
            key={creation.id}
            className="relative shadow-md max-w-2xl m-auto cursor-pointer rounded-lg"
            onClick={() => {
              setIsOpen(true)
              setIndex(0)
            }}
          >
            <GatsbyImage
              image={creation.pictureFeature.gatsbyImageData}
              alt={creation.pictureFeature.title}
              className="bg-white rounded-lg"
            />
            {/* <div
              className="absolute w-full top-12 md:top-24 bg-white py-6 lg:py-10
         opacity-90"
            ></div>
            <div className="text-center w-full absolute top-12 md:top-24 text-lg md:text-xl xl:text-2xl font-mono font-semibold p-3 lg:p-6 ">
              {creation.title}
            </div> */}
          </div>
        </div>
        <div className="mb-6 lg:mb-12 px-6 lg:px-0">
          {creation.pictureGallery.length > 1 && (
            <div className="relative bg-white md:bg-secondary max-w-md m-auto -mt-6 lg:-mt-12 shadow-xl opacity-95 rounded-lg">
              <div className="flex p-4 gap-2 justify-between overflow-hidden">
                {imagesThumb.slice(1, 5).map((img, imgIndex) => {
                  const thumbImage = getImage(img)
                  if (!thumbImage) {
                    return null
                  }
                  return (
                    <div
                      key={imgIndex}
                      className="rounded-lg"
                      onClick={() => {
                        setIsOpen(true)
                        setIndex(imgIndex)
                      }}
                    >
                      <GatsbyImage
                        image={thumbImage}
                        className="rounded-lg cursor-pointer w-12 md:w-16 h-12 md:h-16"
                        alt={img.thumbAlt || ""}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[index]}
            nextSrc={images[(index + 1) % images.length]}
            prevSrc={images[(index + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setIndex(prevIndex)}
            onMoveNextRequest={() => setIndex(nextIndex)}
            imageTitle={images[index].title}
            imageCaption={images[index].caption}
          />
        )}
      </ComponentMotion>
    </div>
  )
}

export default CreationItem
