import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../layouts/layout"
import CreationItem from "../components/section/creation-item"
import { Seo } from "../components/seo"

const CreationsPage = ({ data }) => {
  const isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  const creationsCategories = data.page.creationsCategories

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <div className="bg-secondary">
        <div className="">
          {creationsCategories.map((category) => (
            <div className="border-b border-secondaryPale border-opacity-10">
              <div className=" lg:grid grid-cols-3">
                <div className="border-t border-primary border-opacity-10  flex items-center">
                  <div className="w-full text-center text-xl lg:text-3xl uppercase tracking-widest font-extrabold text-primary items-center py-6 lg:py-0">
                    {category.title}
                  </div>
                </div>
                <div className="col-span-2 bg-primary">
                  <CreationItem creation={category.creationItems} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default CreationsPage

export const Head = ({ data }) => <Seo title={data.page.title} />

export const query = graphql`
  query CreationsPage($slug: String!, $nodeLocale: String!) {
    page: contentfulCreationsPage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      id
      node_locale
      title
      creationsCategories {
        id
        title
        slug
        creationItems {
          id
          title
          slug
          pictureFeature {
            gatsbyImageData(aspectRatio: 1.5)
            title
          }
          pictureGallery {
            gatsbyImageData
            title
          }
        }
      }

      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
  }
`
